<template>
    <v-row>
        <v-col cols="9">
            <!--Input fields-->
            <v-container class="d-flex justify-space-between align-center">
                <v-text-field
                v-if="headerCurrentItem === 'Customer email'"
                v-model="headerCustomerEmail"
                label="Customer email"
                hide-details
                outlined
                ></v-text-field>

                <v-text-field
                v-else-if="headerCurrentItem === 'Customer id'"
                v-model="headerCustomerId"
                label="Customer ID"
                hide-details
                outlined
                ></v-text-field>

                <v-text-field
                v-else-if="headerCurrentItem === 'Subscription id'"
                v-model="headerSubscriptionId"
                label="Subscription ID"
                hide-details
                outlined
                ></v-text-field>

            <v-btn color="secondary" class="ml-5" v-if="showSubmitButton" @click="refreshData">Submit</v-btn>
            </v-container>
        </v-col>
        <v-col cols="3" class="d-flex justify-space-around align-center">
            <!--FILTERS-->
            <v-select
            :items="items"
            label="Filters"
            hide-details=""
            solo
            v-model="headerCurrentItem"
            ></v-select>

            <!--REFRESH-->
            <v-btn
            color="primary"
            fab
            medium
            dark
            class="ml-5 mr-5"
            @click="handleRefreshData"
            >
            <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
    data(){
        return{
            items:[
                'All',
                'Customer email',
                'Customer id',
                'Subscription id'
            ],
        }
    },
    computed:{
        ...mapState('invoices', ['customerEmail', 'customerId', 'subscriptionId', 'currentItem']),
        //Show submit button only if user needs to type some data to filter
        showSubmitButton(){
            let newItems = this.items.slice(1)
            return newItems.includes(this.headerCurrentItem)
        },
        //Vuex state getters and setters
        headerCustomerEmail: {
            get () {
                return this.customerEmail
            },
            set (value) {
                this.UPDATE_CUSTOMER_EMAIL(value)
            }
        },
        headerCustomerId: {
            get () {
                return this.customerId
            },
            set (value) {
                this.UPDATE_CUSTOMER_ID(value)
            }
        },
        headerSubscriptionId: {
            get () {
                return this.subscriptionId
            },
            set (value) {
                this.UPDATE_SUBSCRIPTION_ID(value)
            }
        },
        headerCurrentItem: {
            get () {
                return this.currentItem
            },
            set (value) {
                this.UPDATE_CURRENT_ITEM(value)
            }
        }
    },
    watch:{
        headerCurrentItem:{
            handler(){
                if(this.headerCurrentItem === 'All'){
                    //First get all invoices -> refreshData() knows that it needs to request all invoices
                    this.handleRefreshData()
                }

                //Clear/clean input fields from filters
                this.cleanFilterForms()
            }
        }
    },
    methods:{
        ...mapActions('invoices', ['refreshData']),
        ...mapMutations('invoices', ['UPDATE_PAGE_NUMBER', 'UPDATE_CUSTOMER_EMAIL', 'UPDATE_CUSTOMER_ID', 'UPDATE_SUBSCRIPTION_ID', 'UPDATE_CURRENT_ITEM']),
        //Clean forms from filters
        cleanFilterForms(){
            this.headerCustomerEmail = "";
            this.headerCustomerId = "";
            this.headerSubscriptionId = "";
        },
        handleRefreshData(){
            this.UPDATE_PAGE_NUMBER(1)
            this.refreshData()
        }
    }
}
</script>