<template>
  <v-container fluid>

    <!--Breadcrumbs-->
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>

    <!--Data table-->
    <data-table 
    :headers="headers"
    :data="data"
    :loading="loading"
    :pageNumber="pageNumber"
    :paginate="paginate"
    :options="options"
    >
      <component :is="headerChildComponent"></component>
    </data-table>

  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { itemsSpan, initialize } from '../helpers/index';
import Header from '../components/invoices/Header.vue';
import DataTable from '../components/common/DataTable.vue';
import Breadcrumbs from '../components/common/Breadcrumbs.vue';
export default {
    components:{
      Header,
      DataTable,
      Breadcrumbs
    },
    data(){
        return{
          breadcrumbs: [
              {
                  text: 'Dashboard',
                  href: '/',
              },
              {
                  text: 'Invoices',
                  href: '/invoices',
              },
          ],
          headers: [
              { text: 'Created at', value: 'created' },
              { text: 'Status', value: 'status' },
              { text: 'Billing reason', value: 'billing_reason' },
              { text: 'Collection method', value: 'collection_method' },
              { text: 'Amount due', value: 'amount_due' },
              { text: 'Amount paid', value: 'amount_paid' },
              { text: 'Currency', value: 'currency' },
              { text: 'Customer email', value: 'customer_email' },
              { text: 'Actions', value: 'actions', sortable: false },
          ],
          headerChildComponent: 'Header',
          options:{
            timestamps: [ 'created' ],
            actions: [
              { color: 'primary', hrefName: 'invoice_pdf', icon: 'mdi-cloud-download' },
              { color: 'primary', hrefName: 'hosted_invoice_url', icon: 'mdi-open-in-new' }
            ]
          }
        }
    },
    computed: {
        ...mapState('invoices', ['data', 'pageNumber', 'loading']),
        //Calculation for table footer data
        itemsSpan,
        //Vuex getters and setters for computed property
        invoicesPageNumber: {
            get () {
                return this.pageNumber
            },
            set (value) {
                this.UPDATE_PAGE_NUMBER(value)
            }
        }
    },
    methods: {
      ...mapActions('invoices', ['refreshData']),
      ...mapMutations('invoices', ['UPDATE_PAGE_NUMBER']),
      initialize,
      //Table pagination
      paginate(pageNumber){

      //Updating page number to vuex module
      this.invoicesPageNumber = pageNumber

      //Requesting new data with new pageNumber
      this.refreshData();

      },
    },
    created(){
        //Initializing needed modules
        this.initialize()

        //Stripe customer object needed for getting subscriptions
        // Getting customer from user id
        this.refreshData();
    }
}
</script>

<style>

</style>